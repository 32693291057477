<template>
  <b-overlay
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-card
      v-if=" sendReceiptCard"
      no-body
    >

      <b-row>

        <b-col
          sm="6"
          class="card-border"
        >
          <b-card-body>
            <!-- <div class="pricing-trial-content d-flex justify-content-between"> -->
            <div class="text-center text-md-left mt-3">
              <h3 :class="$store.state.appConfig.layout.skin === 'dark'?'text-white':'text-primary'">
                {{ $t('crypto-deposit-title') }}
              </h3>
              <p>{{ $t('crypto-deposit-description', { currency: formData.currency.currency }) }}</p>
            </div>

            <!-- images -->
            <b-img
              fluid
              :src="require('@/assets/images/illustration/pricing-Illustration.svg')"
              class="pricing-trial-img"
              alt="svg img"
            />
            <!--/ images -->
          </b-card-body>
        </b-col>
        <!-- </div> -->

        <b-col sm="6">
          <b-card-body>
            <b-alert
              class="p-2"
              show
              variant="danger"
            >
              <p v-if="formData.currency.currency">
                {{ $t('deposit-warning-dec', { currency:`${formData.currency.currency} (${formData.currency.network})` }) }}
              </p>
            </b-alert>
            <div class="my-2">
              <metatraderAccountDetail
                :acc="accountDetail"
              />
            </div>
            <b-alert
              v-show="accountDetail.max_deposit || accountDetail.min_deposit"
              id="max-min-deposit-alert"
              show
              variant="warning"
              class="p-1"
            >
              <div class="alert-body">
                <p class="text-justify">
                  {{ $t('deposit-msg', { group: accountDetail.group , min:numberWithCommas(accountDetail.min_deposit || 0) , max:numberWithCommas(accountDetail.max_deposit || 0) }) }}
                </p>
                <p
                  v-if="accountDetail.min_deposit"
                  class="text-justify"
                >
                  {{ $t('min-deposit-msg', { min:numberWithCommas(accountDetail.min_deposit || 0) }) }}
                </p>
                <p
                  v-if="accountDetail.max_deposit"
                  class="text-justify"
                >
                  {{ $t('max-deposit-msg', {max:numberWithCommas(accountDetail.max_deposit || 0) }) }}
                </p>
              </div>
            </b-alert>
            <b-form
              class="mt-2"
            >
              <validation-observer ref="cryptoDepostForm">
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      :label="$t('forex.account-number')"
                      label-for="account-number"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('forex.account-number')"
                        vid="account-number"
                        rules="required"
                      >
                        <v-select
                          id="account-number"
                          v-model="formData.account"
                          :options="accountsItems"
                          :clearable="false"
                          label="account"
                          :dir="$store.state.appConfig.layout.isRTL?'rtl':'ltr'"
                          @input="onchangeAccountDetail"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      :label="$t('forex.platform')"
                      label-for="platform"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('forex.platform')"
                        vid="platform"
                        rules="required"
                      >
                        <v-select
                          id="platform"
                          v-model="formData.platform"
                          :options="platform"
                          label="name"
                          :dir="$store.state.appConfig.layout.isRTL?'rtl':'ltr'"
                          :clearable="false"
                          disabled
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label-for="coin"
                      :label="$t('coin')"
                    >
                      <v-select
                        v-model="formData.currency"
                        :options="currencyItems"
                        label="currencyNetwork"
                        :clearable="false"
                        :dir="$store.state.appConfig.layout.isRTL?'rtl':'ltr'"
                        @input="onCurrencyChange"
                      />
                    </b-form-group>
                    <span
                      v-if="formData.currency.currency"
                      class="text-danger"
                    >{{ $t('deposit-warning-dec2',
                           { currency:`${formData.currency.currency} (${formData.currency.network})` }) }}
                    </span>
                  </b-col>
                  <b-col class="mt-1">
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('Amount')"
                      rules="required|number_or_decimal|decimal_points:2"
                    >
                      <b-row align-v="center">
                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            :label="$t('Amount')"
                            label-for="Amount"
                          >
                            <b-form-input
                              id="Amount"
                              v-model="formData.amount"
                              name="Amount"
                              :placeholder="$t('Amount')"
                              :formatter="numberFormat"
                            />

                          </b-form-group>
                        </b-col>
                      </b-row>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label-for="network"
                      :label="$t('Network')"
                    >
                      <b-form-select
                        id="network"
                        v-model="formData.network"
                        :options="networkItems"
                        text-field="network"
                        value-field="network"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <!-- <b-col>
                    <b-form-group
                      label-size="lg"
                      :label="$t('deposit_Receipt')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('deposit_Receipt')"
                        :rules="'required|size:' + MAX_IMAGE_UPLOAD_SIZE"
                      >
                        <b-form-file
                          id="file-large"
                          v-model="formData.file"
                          size="lg"
                          accept="image/png, image/jpg, image/jpeg"
                          :placeholder="$t('fiat_wallet_deposit_receipt_file')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col> -->
                  <b-col>
                    <b-form-group
                      :label="$t('transaction_id')"
                      label-for="transaction_id"
                    >
                      <b-form-input
                        id="transaction_id"
                        v-model="formData.tx_id"
                        name="transaction_id"
                        :placeholder="$t('transaction_id')"
                      />

                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="walletAddress"
                    cols="12"
                  >
                    <b-form-group
                      id="popover-manual-1"
                      label-for="walletAddress"
                      :label="$t('walletAddress')"
                    >
                      <copy-to-clipboard
                        :text="walletAddress"
                        @copy="pop1 = true"
                      >
                        <b-input-group class="mb-2">
                          <b-form-input
                            id="walletAddress"
                            v-model="walletAddress"
                            readonly
                            name="walletAddress"
                            :placeholder="$t('walletAddress')"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              icon="CopyIcon"
                              size="20"
                            />
                          </b-input-group-append>
                        </b-input-group>
                      </copy-to-clipboard>
                      <b-popover
                        target="popover-manual-1"
                        :show.sync="pop1"
                        triggers="click"
                        placement="bottom"
                      >
                        {{ $t('copied') }}
                      </b-popover>
                    </b-form-group>
                  </b-col>
                </b-row>
              </validation-observer>
            </b-form>
            <div
              class="mt-2 text-center mx-auto d-flex justify-content-center align-center"
            >
              <qrcode-vue
                v-if="walletAddress"
                :value="walletAddress"
                :background="isDarkTheme ? '#283046' : '#fff'"
                :foreground="isDarkTheme ? '#fff' : '#283046'"
                :size="qrsize"
                class="qrcode"
              />
            </div>
            <br>
            <br>
            <b-row v-if="$options.components.bonusForm">
              <b-col>
                <component
                  :is="'bonusForm'"
                  @onAccepted="setBonusCode"
                />
              </b-col>
              <b-button
                variant="primary"
                class="mt-2 mr-1 w-100"
                :disabled="pending"
                @click="save()"
              >
                <b-spinner
                  v-if="pending"
                  small
                  type="grow"
                />
                <span v-if="pending">{{ $t('loading') }}</span>
                <span v-if="!pending">{{ $t('deposit-request') }}</span>
              </b-button>
              <!-- <fileUploadLoading
              :current-upload="currentUpload"
              :upload-size="uploadSize"
              :is-preparing-files="isPreparingFiles"
            /> -->
            </b-row></b-card-body>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="!sendReceiptCard">
      <h4
        class="text-justify"
        style="line-height: normal;"
      >
        {{ $t('deposit-desc') }}
      </h4>
    </b-card>
    <b-overlay
      :show="listloading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-card
        v-if="items.length > 0"
        class="d-none d-sm-block"
        :title="$t('lastDeposits')"
      >
        <b-row align-h="center">
          <b-col cols="12">
            <b-table
              responsive
              striped
              hover
              sticky-header="1000px"
              :items="items"
              :fields="fields"
            >
              <template #cell(date)="data">
                <span>
                  {{ moment(data.item.date).format('YYYY-MM-DD HH:mm:ss') }}
                </span>
              </template>
              <template #cell(amount)="data">
                <span>
                  {{ numberWithCommas(data.item.amount) }}
                </span>
              </template>
              <template
                #cell(tx_id)="data"
              >
                <span
                  class="text-wrap"
                  dir="ltr"
                >
                  <span>
                    {{ data.item.tx_id ? data.item.tx_id.slice(0,10) : null }}
                  </span>
                  <span>..</span>
                  <span>
                    <feather-icon
                      :id="`popover-button-variant3-${data.item.id}`"
                      icon="HelpCircleIcon"
                      class="cursor-pointer  mx-1"
                      size="20"
                      href="#"
                      tabindex="0"
                    />
                    <b-popover
                      placement="top"
                      :target="`popover-button-variant3-${data.item.id}`"
                      variant="secondary"
                      triggers="focus"
                    >
                      <template #title />
                      {{ data.item.tx_id }}
                    </b-popover>
                  </span>

                </span>
              </template>
              <template #cell(status)="data">
                <div class="d-flex align-items-center">
                  <b-button
                    :variant="data.item.detail['status'] === 'pending' ? 'warning' : data.item.detail['status'] === 'successful' ? 'success' :data.item.detail['status']? 'danger':''"
                    size="sm"
                    style="display: block; width: 140px"
                    @click="openInfoTransactionModal(data.item)"
                  >
                    {{ $t(data.item.detail.status) }}
                  </b-button>
                  <feather-icon
                    v-if="data.item.detail['status'] === 'rejected' && data.item.detail['reject_reason']"
                    :id="`popover-button-variant2-${data.item.id}`"
                    icon="HelpCircleIcon"
                    class="cursor-pointer mx-1"
                    size="20"
                    href="#"
                    tabindex="0"
                  />
                  <b-popover
                    v-if="data.item.detail['status'] === 'rejected' && data.item.detail['reject_reason']"
                    placement="top"
                    :target="`popover-button-variant2-${data.item.id}`"
                    variant="secondary"
                    triggers="focus"
                  >
                    {{ data.item.detail['reject_reason'] ? data.item.detail['reject_reason']:'' }}
                  </b-popover>
                </div>

              </template>
            </b-table>

          </b-col>
          <b-col cols="auto">
            <b-pagination
              v-if="totalTransactions > perPageTransactions"
              v-model="currentpageTransactions"
              :total-rows="totalTransactions"
              :per-page="perPageTransactions"
              align="fill"
              size="sm"
              class="mb-1"
            />
          </b-col>
        </b-row>
      </b-card>

      <b-card
        v-if="items.length > 0"
        class="d-sm-none"
        :title="$t('lastDeposits')"
      >
        <b-row
          align-h="center"
        >
          <b-col cols="12">
            <div
              v-for="(item, index) in items"
              :key="index"
              :class="$store.state.appConfig.layout.skin === 'dark' ? 'card-bg' : 'transaction-card'"
              class="mb-2"
            >
              <div class="d-flex justify-content-between">
                <div>{{ $t('id') }}</div>
                <h5>{{ item.automation_id }}</h5>
              </div>
              <div class="d-flex justify-content-between mt-1">
                <div>{{ $t('coin') }}</div>
                <h5>{{ item.currency.currency }}</h5>

              </div>
              <div class="d-flex justify-content-between mt-1">
                <div>{{ $t('Network') }}</div>
                <h5>{{ item.currency.network }}</h5>

              </div>
              <div class="d-flex justify-content-between mt-1">
                <div>{{ $t('amount') }}</div>
                <h5>{{ numberWithCommas(item.amount) }}</h5>

              </div>
              <div
                class="d-flex justify-content-between mt-1"
              >
                <div>{{ $t('transaction_id') }}</div>
                <!-- <h5>{{ item.tx_id }}</h5> -->
                <span class="d-flex">

                  <h5>
                    {{ item.tx_id ? item.tx_id.slice(0,10) : null }}
                  </h5>
                  <h5>..</h5>

                  <feather-icon
                    :id="`popover-button2-variant-${item.id}+mobilee`"
                    icon="HelpCircleIcon"
                    class="cursor-pointer"
                    size="20"
                    href="#"
                    tabindex="0"
                  />
                  <b-popover
                    placement="top"
                    :target="`popover-button2-variant-${item.id}+mobilee`"
                    variant="secondary"
                    triggers="focus"
                  >
                    <template #title />
                    {{ item.tx_id }}
                  </b-popover>

                </span>
              </div>
              <div class="d-flex justify-content-between mt-1">
                <div>{{ $t('date') }}</div>
                <h5>{{ moment(item.date).format('YYYY-MM-DD') }}</h5>

              </div>
              <div class="d-flex justify-content-between mt-1">
                <div>{{ $t('status') }}</div>
                <div class="d-flex align-items-center">
                  <feather-icon
                    v-if="item.detail['status'] === 'rejected' && item.detail.reject_reason"
                    :id="`popover-button-variant-${item.id}+mobile`"
                    icon="HelpCircleIcon"
                    class="cursor-pointer"
                    size="20"
                    href="#"
                    tabindex="0"
                  />
                  <b-popover
                    v-if="item.detail['status'] === 'rejected' && item.detail.reject_reason"
                    placement="top"
                    :target="`popover-button-variant-${item.id}+mobile`"
                    variant="secondary"
                    triggers="focus"
                  >
                    {{ item.detail.reject_reason ? item.detail.reject_reason:'' }}
                  </b-popover>
                  <div style="margin: 0 5px;" />

                  <b-button
                    :variant="item.detail['status'] === 'pending' ? 'warning' : item.detail['status'] === 'successful' ? 'success' :item.detail['status']? 'danger':''"
                    size="sm"
                    style="display: block; width: 140px"
                  > {{ $t(item.detail.status) }}</b-button>
                </div>

              </div>
            </div>
          </b-col>
          <b-col cols="auto">
            <b-pagination
              v-if="totalTransactions > perPageTransactions"
              v-model="currentpageTransactions"
              :total-rows="totalTransactions"
              :per-page="perPageTransactions"
              align="fill"
              size="sm"
              class="mb-1"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>

    <b-modal
      v-model="infoTransactionModal"
      :title="selectedTransaction ? $t('info_transaction_modal', { id: selectedTransaction[0].id }) : ''"
      hide-footer
      size="lg"
      modal-class="transaction-modal"
    >
      <b-alert
        v-if="selectedTransaction && selectedTransaction[0].detail.reject_reason"
        variant="danger"
        show
      >
        <h5 class="alert-heading">
          {{ $t('rejectDescription') }}:
        </h5>
        <div class="alert-body p-2">
          <p>
            {{ selectedTransaction && selectedTransaction[0].detail.reject_reason }}
          </p>
        </div>
      </b-alert>
      <!-- request info -->
      <h5 class="mt-1">
        {{ $t('request_info') }}
      </h5>
      <b-table
        hover
        responsive
        :items="selectedTransaction"
        :fields="transactionModalRequestFields"
      />
      <hr>
    </b-modal>
  </b-overlay>
</template>
<script>
import {
  BOverlay, BForm, BFormGroup, BCard, BRow, BCol, BImg, BCardBody, BTable,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BPopover,
  BButton,
  BPagination,
  BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import Ripple from 'vue-ripple-directive'
import CopyToClipboard from 'vue-copy-to-clipboard'
import QrcodeVue from 'qrcode.vue'
import {
  required, size, numeric, numberOrDecimal,
  decimalPoints,
} from '@validations'
import MetatraderService from '@/modules/forex/service/apis'
import metatraderAccountDetail from '@/modules/forex/views/metatrader/metatraderAccountDetail.vue'
import { resizeImage, numberWithCommas } from '@/utils'
// import fileUploadLoading from '@/views/components/fileUploadLoading.vue'
import moment from '@/libs/moment'
import { alphanumeric } from '@/mixins/alphanumeric'
import ProfileApis from '@/services/apis/profile'

const metaTrader = new MetatraderService()
const profile = new ProfileApis()

export default {
  components: {
    BOverlay,
    BForm,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BImg,
    BCardBody,
    BFormInput,
    CopyToClipboard,
    BInputGroup,
    BInputGroupAppend,
    BPopover,
    QrcodeVue,
    BButton,
    BTable,
    BPagination,
    vSelect,
    metatraderAccountDetail,
    BModal,
    bonusForm: () => process.env.VUE_APP_ACTIVE_MODULES.split(',').includes('BONUS')
     && import('@/modules/bonus/views/components/form.vue'),
    // fileUploadLoading,
  },
  directives: {
    Ripple,
  },
  mixins: [alphanumeric],
  data() {
    return {
      required,
      size,
      numeric,
      moment,
      numberOrDecimal,
      decimalPoints,
      show: false,
      sendReceiptCard: true,
      coin: '',
      walletAddress: '',
      pop1: false,
      currentpageTransactions: 1,
      totalTransactions: 0,
      perPageTransactions: 10,
      qrsize: 150,
      items: [],
      currencyItems: [],
      networkItems: [],
      accountsItems: [],
      platform: [
        { title: 'metatrader 4', value: 'mt4' },
        // { title: 'metatrader 5', value: 'mt5' },
      ],
      formData: {
        account: {},
        platform: '',
        currency: '',
        network: '',
        amount: '',
        // file: null,
        tx_id: '',
      },
      bonusCode: '',
      uploadSize: 0,
      currentUpload: 0,
      // isPreparingFiles: false,
      pending: false,
      listloading: false,
      accountDetail: {
        account: '',
        balance: '',
        equity: '',
        group: '',
        leverage: '',
        margin: '',
      },
      numberWithCommas,
      infoTransactionModal: false,
      selectedTransaction: null,
    }
  },

  computed: {
    isDarkTheme() {
      return this.$store.state.appConfig.layout.skin === 'dark'
    },
    MAX_IMAGE_UPLOAD_SIZE() {
      return process.env.VUE_APP_MAX_IMAGE_UPLOAD_SIZE
    },
    fields() {
      return [
        {
          label: this.$t('id'),
          key: 'automation_id',
          tdClass: 'nameOfTheClass',
        },
        {
          label: this.$t('coin'),
          key: 'currency.currency',
          tdClass: 'nameOfTheClass',
        },
        {
          label: this.$t('Network'),
          key: 'currency.network',
          tdClass: 'nameOfTheClass',
        },
        {
          label: this.$t('amount'),
          key: 'amount',
          tdClass: 'nameOfTheClass',
        },
        {
          label: this.$t('transaction_id'),
          key: 'tx_id',
          tdClass: 'nameOfTheClass',
        },
        {
          label: this.$t('date'),
          key: 'date',
          tdClass: 'nameOfTheClass',
        },
        {
          label: this.$t('status'),
          key: 'status',
          tdClass: 'nameOfTheClass',
        },
      ]
    },
    transactionModalRequestFields() {
      return [
        {
          label: this.$t('account'),
          key: 'account',
        },
        {
          label: this.$t('amount'),
          key: 'amount',
        },
        {
          label: this.$t('currency'),
          key: 'currency',
        },
      ]
    },
  },
  watch: {
    pop1(val) {
      if (val) {
        setTimeout(() => {
          this.pop1 = false
        }, 2500)
      }
    },
    currentpageTransactions(val) {
      if (val) {
        this.list()
      }
    },
    '$store.state.Messaging.event': {
      async handler(newVal) {
        if (newVal === 'platform_forex_deposit_crypto') {
          await this.list()
          this.$store.dispatch('Messaging/setEvent', '')
        }
      },
      immediate: true,
    },
  },

  mounted() {
    this.getData()
    this.list()
    this.getCurrency()
  },
  methods: {
    async getData() {
      this.show = true
      Promise.all([
        metaTrader.getPlatforms().then(res => {
          this.platform = res.data.results
        }),
        await metaTrader.getAccounts().then(res => {
          this.accountsItems = res.data.results
          // eslint-disable-next-line prefer-destructuring
          this.formData.account = res.data.results[0]
          // eslint-disable-next-line prefer-destructuring
          if (res.data.results[0]) this.accountDetail = res.data.results[0]

          this.formData.platform = this.platform.find(plat => plat.value === this.accountDetail.platform)

          if (this.$route.params.id) {
            const accountDetail = res.data.results.find(item => item.account === parseInt(this.$route.params.id, 10))
            this.formData.account = accountDetail
            this.accountDetail = accountDetail
            this.formData.platform = this.platform.find(plat => plat.value === accountDetail.platform)
          }
        }),
      ]).catch(err => {
        console.log(err)
      }).finally(() => {
        this.show = false
      })
    },
    async getCurrency() {
      await metaTrader.currencyList({ type: 'crypto' }).then(res => {
        const currencies = res.data.results.map(item => ({ ...item, currencyNetwork: `${item.currency} (${item.network})` }))
        this.currencyItems = currencies
        const [firstCurrency] = currencies
        this.walletAddress = firstCurrency.address
        this.formData.currency = firstCurrency
        this.networkItems = [{ network: firstCurrency.network }]
        this.formData.network = firstCurrency.network
      })
    },
    onchangeAccountDetail(item) {
      this.accountDetail = item
      this.formData.platform = this.platform.find(plat => plat.value === item.platform)
      const alert = document.getElementById('max-min-deposit-alert')
      const isMobile = window.matchMedia('(max-width: 767px)').matches
      if ((item.max_deposit || item.min_deposit) && alert && isMobile) {
        alert.scrollIntoView()
      }
    },
    async save() {
      const valid = await this.$refs.cryptoDepostForm.validate()
      if (!valid) return
      this.pending = true
      // this.isPreparingFiles = true
      // const imageSize = (process.env.VUE_APP_RESIZE_IMAGE_SIZE ? process.env.VUE_APP_RESIZE_IMAGE_SIZE : 3072) * 1024
      const data = {
        ...this.formData,
        amount: this.formData.amount.replace(/,/g, ''),
        account: this.formData.account.account,
        currency: this.formData.currency.currency,
        platform: this.formData.platform.value,
        ...(this.bonusCode && { bonus_code: this.bonusCode }),
        // file: await resizeImage(this.formData.file, imageSize),
      }
      // this.isPreparingFiles = false
      metaTrader.saveCryptoDeposit(data, e => {
        this.uploadSize = e.total
        this.currentUpload = e.loaded
        profile.get()
      }).then(res => {
        this.$swal({
          title: res.data.message,
          icon: 'success',
          timer: 2500,
          showConfirmButton: false,
        })
        this.sendReceiptCard = false
        this.list()
      }).finally(() => {
        this.pending = false
      })
    },
    list() {
      this.listloading = true
      metaTrader.cryptoDepositList({ per_page: this.perPageTransactions, page: this.currentpageTransactions }).then(res => {
        this.items = res.data.results.data
        this.totalTransactions = res.data.results.paginate.total
        this.currentpageTransactions = res.data.results.paginate.current_page
        this.perPageTransactions = Number(res.data.results.paginate.per_page)
      }).finally(() => {
        this.listloading = false
      })
    },
    onCurrencyChange(e) {
      this.walletAddress = e.address
      this.networkItems = [{ network: e.network }]
      this.formData.network = e.network
    },
    numberFormat(value) {
      // eslint-disable-next-line eqeqeq
      return this.numberWithCommas(value)
    },
    openInfoTransactionModal(val) {
      this.infoTransactionModal = true
      this.selectedTransaction = [{
        ...val,
        currency: val.currency.currency,
        amount: this.numberWithCommas(val.amount),
      }]
    },
    setBonusCode(code) {
      this.bonusCode = code
    },
  },

}
</script>
  <style lang="scss">
  .card-border {
    border-right: 1px solid #e3e1e9;
  }

  .transaction-card {
    background-color: #efefef;
    padding: 15px;
    border-radius: 5px;
  }

  .card-bg {
    background-color: #172238;
    padding: 15px;
    border-radius: 5px;
  }

  .nameOfTheClass {
    padding: 20px !important;
  }
  </style>
  <style scoped>
  ::v-deep .qrcode{
    width: 150px;
    height: 150px;
  }
  ::v-deep .qrcode canvas{
    width: 100% !important;
  }
  </style>
